<template>
    <div class="row">
             <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Everyone</label>
                    <div class="domain-list">
                        <div class="list-item ng-scope">
                            <button class="icon-button button-delete" type="button">
                                <i class="fa fa-times-circle"></i>
                            </button>
                            <span>Developer@example.com</span>
                        </div>
                        <div class="list-item ng-scope">
                            <button class="icon-button button-delete" type="button">
                                <i class="fa fa-times-circle"></i>
                            </button>
                            <span>all@example.com</span>
                        </div>
                    </div>
                </div>
             </div>
             <div class="col-md-6">
                 <div class="form-group">
                     <label class="control-label text-left">Add Agent</label>
                     <select class="form-control">
                       <option>Jhon Doe</option>
                     </select>
                 </div>
             </div>
            
           </div>  
 </template>
 <style src="@vueform/toggle/themes/default.css"></style>
 <script>
     import Toggle from '@vueform/toggle'
     import Multiselect from '@vueform/multiselect'
     import iServiceSwitch from '../components/iServiceSwitch.vue';
     import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
 
     export default {
       name: 'New Agent List',
       props: ['formTitle'],
        components: {
          Multiselect,
          iServiceSwitch,
          Toggle,
          VariableAndStockResponses
       },
       data: function () {
         return {
            allowmultiple : false,
            customerviewable : false
         }
       },
       events: {
        
       },
       methods: {
       
       }
 
     }
 </script>