<template>
    <div class="row">
          <div class="form-verticle"> 
            <div class="form-group">
              <lable class="control-lable col-md-3">Choose Time Slot & Color</lable>
              <div class="col-12 col-sm-12 col-md-8 col-lg-5">                  
                  <div class="simple-label multiple pos-rel">
                       <div class="input-with-desc">
                          <div class="group-property-1">
                            <label>Question Age</label>
                          </div>
                          <div class="group-property-2">
                            <label class="pleft-5">Color</label>
                          </div>
                      </div>
                      <div class="mbottom-15" v-for="(val, index) of optionparameter">
                        <div class="float-btn-group" >
                            <button class="margin-5 icon-button button-delete" type="button" @click="removeValue(index)"><i class="fa fa-times-circle"></i></button>
                            <ColorPickerInstance></ColorPickerInstance>
                        </div>
                        <span class="mx-3 text-red" v-if="index == 1" v-show="showerror"><i class="fa fa-warning"></i> Duplicate question age is not allowed.</span>
                      </div>
                      <button class="margin-5 icon-button button-add-row" type="button" @click="addValue"><i class="fa fa-plus-circle"></i></button>
                  </div>
              </div>
            </div>
          </div>        
          <div class="col-md-12"><button class="btn btn-primary" v-on:click="showerror = true">Save</button></div>
    </div>
</template>
<script>
import { ColorPicker } from "vue-accessible-color-picker";
import ColorPickerInstance from '../components/ColorPickerInstance.vue';

var previousrowVal;
export default {
  name: 'Color Codes Config',
  components: {
    ColorPicker,
    ColorPickerInstance
  },
  data: function(){
      return{
        segment3 : false,
        segment4 : false,
        segment5 : false,
        segment12 : false,
        showColorCodesDetails : false,
        value: true,
        active_el : '',
        colEmail : false,
        createNewColorCodes:false,
        showAlertColorCodes:false,
         color: '#e0e0e0',
         colpicker1:false,
          optionparameter : [1],
          searchlength : 1,
          spliton : false,
          showerror:false
      }
         
  },
   methods: {
    toggleSegmentCollapsedStateDahboard(i) {
      this['segment'+i] = !this['segment'+i] 
    },
    showMessageDashboardDetails(type,row) {
      if(previousrowVal == row){
          this.showColorCodesDetails = !this.showColorCodesDetails;
          if(!this.showColorCodesDetails){
            this.active_el = '';
          }else{
            this.active_el = row;  
          }
         
      }else{
         this.showColorCodesDetails = this.showColorCodesDetails;
            this.active_el = row;     
      }
      previousrowVal = row;
      
      this.showColorCodesDetails = true;
      this.createNewColorCodes = false;
      
      //this.emitter.emit("show-message-details", {displayType:type, showColorCodesDetails : this.showColorCodesDetails});
      
    },
    updateColor (eventData) {
      this.color = eventData.cssColor
    },
    addValue() {
      this.optionparameter.push('')
      this.searchlength = parseInt(this.searchlength) + 1;
   },
   removeValue(index) {
      if(this.optionparameter.length > 1){
       this.optionparameter.splice(index, 1);
       this.searchlength = parseInt(this.searchlength) - 1;
      }
    },
    splitGroup(){
       this.spliton = true
    }
  }
}

</script>