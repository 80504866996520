<template>
    <div class="row">
        <div class="col-md-12">
        <!-- Custom Tabs -->
            <div class="row">
                <div class="col-md-12">
                    <div class="box box-info">
                        <div class="box-body table-responsive">
                        <div class="notdatatable form-inline mbottom-15">
                            <div class="table-controls table-columns">
                                <div class="btn-group"><button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                            </div>
                            <div class="table-controls table-pagesize table-columns">
                                <label>
                                    Show 
                                    <select class="form-control input-xs">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    </select>
                                    entries 
                                </label>
                            </div>
                            
                            <div class="table-controls table-filter">Filter Tags <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                        </div>
                        <table class="table table-striped table-hover">
                            <thead>
                            <tr id="row1">
                                <!--<th class="no-sort"  name="name" width="30"></th>-->
                                <th class="text-capitalize sorting">
                                    ID
                                </th>
                                <th class="text-capitalize sorting">
                                    Tag Value
                                </th>
                                <th class="text-capitalize sorting">
                                   Topic Value
                                </th>
                                <th class="text-capitalize sorting">
                                    My Work Queues Link Name
                                </th>
                                <th class="text-capitalize sorting">
                                    Manage Work Queues Link Name
                                </th>
                              
                            </tr>
                            </thead>
                            <tbody>
                            <tr id="row2" @click="showMessageDetails('','row2')" v-bind:class = "showDetails.active_el == 'row2' ?'row-active':''">
                               
                                <td>
                                	1
                                </td>
                                <td>
                                    Estimate
                                </td>
                                <td>
                                    --
                                </td>
                                <td>
                                    My Project
                                </td>
                                <td>
                                  Manage Project
                                </td>
                         
                            </tr>
                            </tbody>
                        </table>
                        <div id="Main_MyDashboard_Recent_TableInfo">
                            <div class="notdatatable table-info">Showing 1 to 2 of 2 entries</div>
                            <div class="dataTables_paginate notdatatable table-pagination text-end">
                                <ul class="pagination">
                                    <li><a href="">Previous</a></li>
                                    <li class="active"><a href="">1</a></li>
                                    <li><a href="">Next</a></li>
                                </ul>
                            </div>
                            </div>
                        <!-- Data loader start
                        <div class="data-loader">
                            <i class="fa fa-spinner fa-spin fa-fw"></i>
                        </div>  -->
                        <!-- Data loader end-->
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                </div>
            </div>
        </div>
        <!-- /.col -->
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <button  class="btn btn-primary" v-on:click="showNewAlert()">New Tag Exclusion</button>
          <!-- <button v-if="showDetails.showAgentListDetails"  class="btn btn-danger" v-on:click="showDeleteAlert()">Delete Agent List</button>-->
        </div>
      </div>
    </div>

    <div class="expandedrowcontent box-bg-color" v-if="showDetails.showNew">
        <div class="box box-info with-border">
            <div class="box-body table-responsive">
                <div class="box-header with-border">
                    <i class="fa fa-tag"></i>
                    <h3 class="box-title text-right">New Tag</h3>
                </div>
                <div class="box-body">
                    <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label text-left">Tag Value</label>
                            <input iservice-id="Name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label text-left">Topic Value</label>
                            <input iservice-id="Name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label text-left">My Work Queues Link Name</label>
                            <input iservice-id="Name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label text-left">Message Work Queues Link Name</label>
                            <input iservice-id="Name" type="text" class="form-control ">
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="showDetails.deleteAlert">
        <div class="col-md-12" iservice-id-prefix="'Delete'">
            <div class="box box-danger" iservice-id="'Delete'">
                <div class="box-header with-border">
                    <i class="fa fa-warning"></i>
                    <h3 class="box-title text-right">Delete StockResponse Property</h3>
                </div>
                <div class="box-body">
                    <section class="box-content box-bg-color">
                    <div class="row">
                        <div class="form-horizontal">
                            <div class="col-md-12">
                                <p>All service levels that reference this StockResponse property will lose this value when deleted.</p>
                                <p class="text-danger">WARNING: Deletion is immediate and irrevocable.</p>
                            </div>
                        </div>
                        <div class="form-horizontal">
                            <div class="col-md-12"><button iservice-id="Confirm" class="btn btn-danger btn-sm">Confirm Deletion</button><button iservice-id="Cancel" class="btn btn-link" v-on:click="cancel()">Cancel</button></div>
                        </div>
                    </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
  
    <div  v-if="showDetails.showAgentListDetails">
        <div class="box box-info with-border">
            <div class="box-body">
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label text-left">Tag Value</label>
                            <input iservice-id="Name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label text-left">Topic Value</label>
                            <input iservice-id="Name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label text-left">My Work Queues Link Name</label>
                            <input iservice-id="Name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label text-left">Message Work Queues Link Name</label>
                            <input iservice-id="Name" type="text" class="form-control ">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import NewWorkflowPropertyForm from '../components/NewWorkflowPropertyForm.vue';
import WorkflowNewAgentList from '../components/WorkflowNewAgentList.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Workflow Agent List',
   components: {
    iServiceSwitch,
    Multiselect,
    NewWorkflowPropertyForm,
    Toggle,
    Multiselect,
    WorkflowNewAgentList
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showAgentListDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showAgentListDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showAgentListDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showAgentListDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
