<template>
    <div class="row">
      <div class="box box-info">
         <div class="box-body">
            <div class="row">
             <div class="col-md-4">
                 <div class="form-group">
                     <label class="control-label text-left"> Name</label><input iservice-id="Name" type="text" class="form-control"><!--v-if-->
                 </div>
             </div>
             <div class="col-md-4">
                 <div class="form-group">
                     <label class="control-label text-left">Value Type</label>
                     <select class="form-control">
                       <option>Text</option>
                       <option>Multichoice</option>
                       <option>Date</option>
                       <option>Date+Time</option>
                       <option>Number</option>
                       <option>Choice from data file</option>
                       <option>Data file row value</option>
                       <option>Data file row value</option>
                       <option>Row total (A*B-C)</option>
                       <option>Row total (A+B)</option>
                       <option>Group by query</option>
                       <option>Grid column total</option>
                     </select>
                     <span>User can enter a simple single line text value (450 chars max).</span>
                 </div>
             </div>
             <div class="col-md-12 mt-3">
                 <div class="row">
                    <div class="col-md-4">
                       <div class="form-group">
                             <label class="control-label text-left"> 
                                <Toggle/> Index this property for interaction search
                            </label>
                        </div>
                        <div class="form-group">
                             <label class="control-label text-left"> 
                                <Toggle/> Enforce unique value for this property
                            </label>
                        </div>
                        <div class="form-group">
                             <label class="control-label text-left"> 
                                <Toggle/> Allow multiple value
                            </label>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="nested-panel-heading skill-heading" v-if="prop == 'multichoice'"><h3 class="nested-title">Multiple Choice Settings</h3></div>
                        
                        <div class="row" v-if="prop == 'multichoice'">
                           <div class="col-md-5">
                                 <div class="form-group">
                                    <label class="control-label text-left">Value Choices</label>
                                    <select class="form-control">
                                    <option>Text</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-1 text-center">
                                <i class="fa fa-arrow-left text-primary mt-4"></i>
                            </div>
                            <div class="col-md-5">
                                <label class="control-label text-left">&nbsp;</label>
                                <div class="input-group mbottom-15"><input type="text" class="form-control"><span class="input-group-btn"><button type="button" class="btn btn-info btn-flat">Add Values</button></span></div>
                            </div>
                        </div>

                        <div class="row" v-if="prop == 'number'">
                            <div class="col-md-12">
                                <div class="nested-panel-heading skill-heading"><h3 class="nested-title">Number Settings</h3></div>
                                <div class="row">
                                <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-left"> 
                                                <Toggle/> Negative values not allowed
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-6">
                                        <div class="form-group mbottom-15">
                                            <div style=""><label class="control-label text-left margin-r-5">Number of Decimal Type</label>
                                                <a class="btn btn-xs margin-r-5 btn-default">Auto</a>
                                                <input type="text" class="form-control input-width-time d-inline" value="2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="prop == 'datafield'">
                            <div class="col-md-8">
                                <div class="nested-panel-heading skill-heading"><h3 class="nested-title">Choice From Data File Field</h3></div>
                                <div class="row">
                                <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="control-label text-left"> 
                                               Lookup Field
                                            </label>
                                            <Multiselect
                                                v-model="value"
                                                placeholder="Search in list"
                                                label="name"
                                                class="is-invalid"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: 'Search in list',
                                                    options:  [
                                                        { value: '....', name: '....',level:'1'},
                                                       
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-12">
                                        <div class="form-group mbottom-15">
                                            <div style=""><label class="control-label text-left margin-r-5">Field Width</label>
                                                <input type="text" class="form-control small-input">
                                                <div><span>
                                                    Width of this field (in pixel) in the grid
                                                </span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="prop == 'datafile'">
                            <div class="col-md-12">
                                <div class="nested-panel-heading skill-heading"><h3 class="nested-title">Data File Row Value Settings</h3></div>
                                <div class="row">
                                  <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="control-label text-left"> 
                                               Lookedup Value
                                            </label>
                                            <Multiselect
                                                v-model="value"
                                                placeholder="Search in list"
                                                label="name"
                                                class="is-invalid"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: 'Search in list',
                                                    options:  [
                                                        { value: 'Material', name: 'Material',level:'1'},
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="control-label text-left"> 
                                               Lookedup Field
                                            </label>
                                            <Multiselect
                                                v-model="value"
                                                placeholder="Search in list"
                                                label="name"
                                                class="is-invalid"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: 'Search in list',
                                                    options:  [
                                                        { value: 'Price List:Material', name: 'Price List:Material',level:'1'},
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>

                                        </div>
                                    </div>

                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="control-label text-left"> 
                                               Fetch Field
                                            </label>
                                            <Multiselect
                                                v-model="value"
                                                placeholder="Search in list"
                                                label="name"
                                                class="is-invalid"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: 'Search in list',
                                                    options:  [
                                                        { value: 'Price List:Material', name: 'Price List:Material',level:'1'},
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>

                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="form-group mbottom-15">
                                                    <div style=""><label class="control-label text-left margin-r-5">Field Width</label>
                                                        <input type="text" class="form-control small-input">
                                                        <div><span>
                                                            Width of this field (in pixel) in the grid
                                                        </span></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group mt-4">
                                                    <div style="">
                                                        <label class="control-label text-left margin-r-5">Number of Decimal Type</label>
                                                        <a class="btn btn-xs margin-r-5 btn-default">Auto</a>
                                                        <input type="text" class="form-control input-width-time d-inline" value="2">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="prop == 'rowtotal'">
                            <div class="col-md-12">
                                <div class="nested-panel-heading skill-heading"><h3 class="nested-title">  Row Total Value (A * B - C) Settings</h3></div>
                                <div class="row">
                                  <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="control-label text-left"> 
                                               Field A
                                            </label>
                                            <Multiselect
                                                v-model="value"
                                                placeholder="--Select--"
                                                label="name"
                                                class="is-invalid"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: '--Select--',
                                                    options:  [
                                                        { value: 'Price', name: 'Price',level:'1'},
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                            
                                        </div>
                                    </div>

                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="control-label text-left"> 
                                               Field B
                                            </label>
                                            <Multiselect
                                                v-model="value"
                                                placeholder="--Select--"
                                                label="name"
                                                class="is-invalid"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: '--Select--',
                                                    options:  [
                                                        { value: 'Price', name: 'Price',level:'1'},
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                            <div><span>If no field is specified, the value is assume to be 1.</span></div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="control-label text-left"> 
                                               Field C
                                            </label>
                                            <Multiselect
                                                v-model="value"
                                                placeholder="--Select--"
                                                label="name"
                                                class="is-invalid"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: '--Select--',
                                                    options:  [
                                                        { value: 'Price', name: 'Price',level:'1'},
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                            <div><span>If no field is specified, the value is assume to be 0.</span></div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="form-group mbottom-15">
                                                    <div style=""><label class="control-label text-left margin-r-5">Field Width</label>
                                                        <input type="text" class="form-control small-input">
                                                        <div><span>
                                                            Width of this field (in pixel) in the grid
                                                        </span></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group mt-4">
                                                    <div style="">
                                                        <label class="control-label text-left margin-r-5">Number of Decimal Type</label>
                                                        <a class="btn btn-xs margin-r-5 btn-default">Auto</a>
                                                        <input type="text" class="form-control input-width-time d-inline" value="2">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div class="row" v-if="prop == 'rowtotal2'">
                            <div class="col-md-12">
                                <div class="nested-panel-heading skill-heading"><h3 class="nested-title">  Row Total Value (A + B) Settings</h3></div>
                                <div class="row">
                                  <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="control-label text-left"> 
                                               Field A
                                            </label>
                                            <Multiselect
                                                v-model="value"
                                                placeholder="--Select--"
                                                label="name"
                                                class="is-invalid"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: '--Select--',
                                                    options:  [
                                                        { value: 'Price', name: 'Price',level:'1'},
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                            
                                        </div>
                                    </div>

                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="control-label text-left"> 
                                               Field B
                                            </label>
                                            <Multiselect
                                                v-model="value"
                                                placeholder="--Select--"
                                                label="name"
                                                class="is-invalid"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: '--Select--',
                                                    options:  [
                                                        { value: 'Price', name: 'Price',level:'1'},
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                        </div>
                                    </div>
                                   

                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="form-group mbottom-15">
                                                    <div style=""><label class="control-label text-left margin-r-5">Field Width</label>
                                                        <input type="text" class="form-control small-input">
                                                        <div><span>
                                                            Width of this field (in pixel) in the grid
                                                        </span></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group mt-4">
                                                    <div style="">
                                                        <label class="control-label text-left margin-r-5">Number of Decimal Type</label>
                                                        <a class="btn btn-xs margin-r-5 btn-default">Auto</a>
                                                        <input type="text" class="form-control input-width-time d-inline" value="2">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>

                 </div>
             </div> 
           </div>
         </div>
       </div> 
    </div>
 </template>
 <style src="@vueform/toggle/themes/default.css"></style>
 <script>
     import Toggle from '@vueform/toggle'
     import Multiselect from '@vueform/multiselect'
     import iServiceSwitch from '../components/iServiceSwitch.vue';
     import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
 
     export default {
       name: 'New Workflow Property Form',
       props: ['prop'],
        components: {
          Multiselect,
          iServiceSwitch,
          Toggle,
          VariableAndStockResponses,
     
       },
       data: function () {
         return {
            allowmultiple : false,
            customerviewable : false
         }
       },
       events: {
        
       },
       methods: {
       
       }
 
     }
 </script>