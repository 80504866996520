<template>
    <div class="nav-tabs-custom">
         <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
               <a class="nav-link active" v-bind:id="'tab11'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_11_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-code-fork"></i> <span>Answer Panel Buttons</span></a>
            </li>
            <li class="nav-item" role="presentation">
               <a class="nav-link" v-bind:id="'tab21'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_21_'+tabOption" type="button" role="tab"><i class="fa fa-eyedropper"></i> <span>Question Color Coding By Age</span></a>
            </li>
            <li class="nav-item" role="presentation">
               <a class="nav-link" v-bind:id="'tab31'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_31_'+tabOption" type="button" role="tab"><i class="fa fa-dashboard"></i> <span>Dashboard Settings</span></a>
            </li>
             <li class="nav-item" role="presentation">
               <a class="nav-link" v-bind:id="'tab41'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_41_'+tabOption" type="button" role="tab"><i class="fa fa-user"></i> <span>Agent Login Panel</span></a>
            </li>
            <li class="nav-item" role="presentation">
               <a class="nav-link" v-bind:id="'tab42'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_42_'+tabOption" type="button" role="tab"><i class="fa fa-reply"></i> <span>Answer With AI Prompt</span></a>
            </li>
            <li class="nav-item" role="presentation">
               <a class="nav-link" v-bind:id="'tab43'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_43_'+tabOption" type="button" role="tab"><i class="fa fa-tag"></i> <span>Tagged Interactions</span></a>
            </li>
         </ul>
         <div class="tab-content" id="myTabContent">
            <div class="tab-pane show active" v-bind:id="'tab_11_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
                <div class="form-horizontal">
                  <div class="form-group">
                    <lable class="control-lable col-md-2">Select Answer Panel</lable>
                    <div class="col-md-4">
                      <select class="form-control">
                        <option>--Select--</option>
                        <option>Full Send Menu</option>
                        <option>Secure Portal - No Notification</option>
                        <option>Secure Portal - With Notification</option>
                      </select>
                     </div>
                  </div>
                </div>
                <div>
                   <button class="btn btn-primary">Save</button>
                </div>
            </div>

            <div class="tab-pane fade" v-bind:id="'tab_21_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
               <ColorCodesConfig></ColorCodesConfig>  
            </div>

            <div class="tab-pane fade" v-bind:id="'tab_31_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
              <DashbordConfig></DashbordConfig>
            </div>

            <div class="tab-pane fade" v-bind:id="'tab_41_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
             
                <div class="form-horizontal">
                  <div class="form-group">
                    <lable class="control-lable col-md-2">Customer Redirect URL <span class="data-v-tooltip" data-v-tooltip="Enter the URL for your customer redirect page. When a value is provided, a link will be added to the Agent Login Panel (I am not an agent). When no value is provided, the customer redirect link will not be shown." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                        <i class="fa fa-question-circle text-info help-icon"></i>
                      </span>
                    </lable>
                    <div class="col-md-8">
                      <input type="text" class="form-control max-ip-width min-md-ip-w min-sm-ip-w" >
                    </div>
                  </div>
                </div>
                <div class="mt-3 mb-3">
                  <button class="btn btn-primary">Save</button>
                </div>
             
            </div>

            <div class="tab-pane fade" v-bind:id="'tab_42_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
               <div>
                <table class="table table-striped">
                <thead>
                    <tr role="row">
                        <th class="no-sort" name="name" tabindex="0" aria-controls="historyTabCaseView" rowspan="1" colspan="1"></th>
                        <th>
                          Prompt Name
                        </th>
                       
                        <th>
                            Prompt Verbiage
                        </th>
                    
                        <th>
                            &nbsp;
                        </th>
                    
                    </tr>
                </thead>
                    
                    <tbody>
                    <tr role="row">
                        <td name="name" class="col-icon-check sorting_1">
                          <div class="d-flex"> 
                           <button class="btn button-delete" v-show="!editrow1">
                                    <i class="fa fa-times-circle"></i>
                            </button>
                            <button class="btn btn-xs btn-link text-default" v-on:click="editrow1 = !editrow1" v-show="!editrow1">
                                <i class="fa fa-pencil"></i>
                            </button>
                          </div>
                        </td>
                        <td  style="width: 210px;">
                            <span v-show="!editrow1">
                              Set visit date 
                            </span>
                            <input type="text" class="form-control inline-form-control" value="_Out of Office Notices" v-show="editrow1">
                        </td>
                        <td>
                            <span href="" v-show="!editrow1">I will present you with a request from a customer for quote request.</span>
                            <div v-show="editrow1">
                              <textarea class="form-control" placeholder="Enter List of Your Email Addresses"></textarea>
                            </div>
                        </td>
                       
                        <td>
                            <button class="btn btn-xs btn-primary" v-show="editrow1">
                                    Save
                            </button>
                            <button class="btn btn-xs btn-link" v-show="editrow1" v-on:click="editrow1 = !editrow1">
                                    Cancel
                            </button>
                        </td>
                        
                    </tr>
                       
                        <tr role="row" >
                                <td name="name" class="col-icon-check sorting_1">
                                 <div class="d-flex">
                                  <button class="btn button-delete" v-show="!editrow2">
                                        <i class="fa fa-times-circle"></i>
                                   </button>
                                    <button class="btn btn-xs btn-link text-default" v-on:click="editrow2 = !editrow2" v-show="!editrow2">
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                  </div>
                                </td>
                            <td style="width: 210px;">
                                <span v-show="!editrow2">
                                Call Center Management
                                </span>
                                <input type="text" class="form-control inline-form-control" value="Call Center Management" v-show="editrow2">
                        
                            </td>
                            <td>
                                <span v-show="!editrow2"> Ask for details I will present you with a question from a customer that is missing some details</span>
                               <div v-show="editrow2">
                                <textarea class="form-control" placeholder="Enter List of Your Email Addresses"></textarea>
                                </div>
                            </td>
                           
                            <td>
                               
                                <button class="btn btn-xs btn-primary" v-show="editrow2">
                                        Save
                                </button>
                                <button class="btn btn-xs btn-link" v-show="editrow2" v-on:click="editrow2 = !editrow2">
                                        Cancel
                                </button>
                            </td>
                            
                        </tr>

                        <tr role="row" v-if="addanother == true">
                                <td name="name" class="col-icon-check sorting_1">
                                 <div class="d-flex">
                                  <button class="btn button-delete" v-show="!editrow3">
                                        <i class="fa fa-times-circle"></i>
                                   </button>
                                    <button class="btn btn-xs btn-link text-default" v-on:click="editrow3 = !editrow3" v-show="!editrow3">
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                  </div>
                                </td>
                            <td style="width: 210px;">
                                <span v-show="!editrow3">
                                Call Center Management
                                </span>
                                <input type="text" class="form-control inline-form-control" value="" v-show="editrow3">
                        
                            </td>
                            <td>
                                <span v-show="!editrow3"> Ask for details I will present you with a question from a customer that is missing some details</span>
                               <div v-show="editrow3">
                                <textarea class="form-control" placeholder=""></textarea>
                                </div>
                            </td>
                           
                            <td>
                               
                                <button class="btn btn-xs btn-primary" v-show="editrow3">
                                        Save
                                </button>
                                <button class="btn btn-xs btn-link" v-show="editrow3" v-on:click="addanother = false">
                                        Cancel
                                </button>
                            </td>
                            
                        </tr>
                     
                </tbody>
                </table>
                </div>
               <div class="row"><button class="btn btn-lg btn-link" type="button" v-on:click="addanother = true"><i class="fa fa-plus-circle text-light-blue"></i> Add Another Prompt </button></div>
            </div>

            <div class="tab-pane fade" v-bind:id="'tab_43_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
               <p>Use this page to control the display of interactions based on their tags. Some tagged interactions have a custom user interface and should not be combined with incoming question in the message queues. When you define the behaviour for a tag, you must specify the details for the alternate display panel.</p>
                <TaggedInteraction></TaggedInteraction>
            </div>
         </div>
    </div>
</template>
<script>
import DashbordConfig from '../components/DashbordConfig.vue';
import ColorCodesConfig from '../components/ColorCodesConfig.vue';
import TaggedInteraction from '../components/TaggedInteraction.vue';
var previousrowVal;
export default {
  name: 'Segment Level Settings',
  components: {
    DashbordConfig,
    ColorCodesConfig,
    TaggedInteraction
  },
  data: function(){
      return{
        editrow1 : false,
            editrow2 : false,
            editrow3 : true,
            addanother : false
      }     
  },
  ready () {
    $(document).ready(function ($) {
    })
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    },
    showMessageDetails(type,row) {
      if(previousrowVal == row){
          this.showDetails = !this.showDetails;
          if(!this.showDetails){
            this.active_el = '';
          }else{
            this.active_el = row;  
          }
         
      }else{
         this.showDetails = this.showDetails;
            this.active_el = row;     
      }
      previousrowVal = row;
      this.emitter.emit("show-message-details", {displayType:type, showDetails : this.showDetails});
      
    }
  }
}
</script>