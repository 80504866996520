<template>
  <div class="admin-customize-dashboard">
   <div class="row">
      <div class="col-md-12">
         <div class="dashboard-row">
            <div class="row">
               <div class="col-md-10">
                  <div class="row">
                     <div class="col-md-6">
                        <div class="dashboard-cell">
                           <div class="form-group">
                              <label class="col-12 control-label text-left">Container Width</label>
                              <div class="col-12">
                                 <select class="col-12 form-control">
                                    <option>2 Column</option>
                                    <option>4 Column</option>
                                    <option>6 Column</option>
                                    <option>8 Column</option>
                                    <option>10 Column</option>
                                    <option>12 Column</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-check">
                              <div class="col-auto">
                                 <div class="form-check form-check-inline">
                                       <input class="form-check-input" type="radio" name="radioFilter" checked>
                                       <label class="form-check-label" for="inlineRadio1">Component</label>
                                 </div>
                                 <div class="form-check form-check-inline">
                                       <input class="form-check-input" type="radio" name="radioFilter">
                                       <label class="form-check-label" for="inlineRadio2">HTML</label>
                                 </div>
                              </div>
                           </div>
                           <div class="form-group">
                              <label class="col-12 control-label text-left">Component</label>
                              <div class="col-12">
                               <select class="col-12 form-control">
                                   <option>Recent Interaction</option>
                                    <option>Answer This Week</option>
                                    <option>Get Support</option>
                                 </select>
                              </div>
                           </div>
                           <div class="col-md-12  mbottom-15">
                              <!--v-if--><span><a class="btn btn-xs btn-primary">Right</a></span>
                              <span><a class="btn btn-xs btn-danger">Remove</a></span>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="dashboard-cell">
                           <div class="form-group">
                              <label class="col-12 control-label text-left">Container Width</label>
                              <div class="col-12">
                                 <select class="col-12 form-control">
                                    <option>2 Column</option>
                                    <option>4 Column</option>
                                    <option>6 Column</option>
                                    <option>8 Column</option>
                                    <option>10 Column</option>
                                    <option>12 Column</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-check">
                              <div class="col-auto">
                                 <div class="form-check form-check-inline">
                                       <input class="form-check-input" type="radio" name="radioFilter" checked>
                                       <label class="form-check-label" for="inlineRadio1">Component</label>
                                 </div>
                                 <div class="form-check form-check-inline">
                                       <input class="form-check-input" type="radio" name="radioFilter">
                                       <label class="form-check-label" for="inlineRadio2">HTML</label>
                                 </div>
                              </div>
                           </div>
                           <div class="form-group">
                              <label class="col-12 control-label text-left">Component</label>
                              <div class="col-12">
                               <select class="col-12 form-control">
                                   <option>Recent Interaction</option>
                                    <option>Answer This Week</option>
                                    <option>Get Support</option>
                                 </select>
                              </div>
                           </div>
                           <div class="col-md-12  mbottom-15">
                             <span><a class="btn btn-xs btn-primary">Left</a></span>
                             <span><a class="btn btn-xs btn-primary">Right</a></span>
                             <span><a class="btn btn-xs btn-danger">Remove</a></span></div>
                        </div>
                     </div>
                     <div class="col-md-12">
                        <div class="dashboard-cell">
                           <div class="form-group">
                              <label class="col-12 control-label text-left">Container Width</label>
                              <div class="col-12">
                                 <select class="col-12 form-control">
                                    <option>2 Column</option>
                                    <option>4 Column</option>
                                    <option>6 Column</option>
                                    <option>8 Column</option>
                                    <option>10 Column</option>
                                    <option>12 Column</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-check">
                              <div class="col-auto">
                                 <div class="form-check form-check-inline">
                                       <input class="form-check-input" type="radio" name="radioFilter" checked>
                                       <label class="form-check-label" for="inlineRadio1">Component</label>
                                 </div>
                                 <div class="form-check form-check-inline">
                                       <input class="form-check-input" type="radio" name="radioFilter">
                                       <label class="form-check-label" for="inlineRadio2">HTML</label>
                                 </div>
                              </div>
                           </div>
                           
                           <div class="form-group">
                              <label class="col-12 control-label text-left">Component</label>
                              <div class="col-12">
                               <select class="col-12 form-control">
                                    <option>Recent Interaction</option>
                                    <option>Answer This Week</option>
                                    <option>Get Support</option>
                                    
                                 </select>
                              </div>
                           </div>
                           <div class="col-md-12 mbottom-15">
                              <span><a class="btn btn-xs btn-primary">Left</a></span><!--v-if-->
                              <span><a class="btn btn-xs btn-danger">Remove</a></span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-2 pt-3">
                  <!--v-if--><span><a class="btn btn-xs btn-primary mbottom-15">Row Down</a><br></span><span><a class="btn btn-xs btn-primary mbottom-15">Add Column</a></span><span><a class="btn btn-xs btn-danger mbottom-15">Remove Row</a></span>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-md-12">
         <div class="dashboard-row">
            <div class="row">
               <div class="col-md-10">
                  <div class="row">
                     <div class="col-md-12">
                        <div class="dashboard-cell">
                           <div class="form-group">
                              <label class="col-12 control-label text-left">Container Width</label>
                              <div class="col-12">
                                 <select class="col-12 form-control">
                                    <option>2 Column</option>
                                    <option>4 Column</option>
                                    <option>6 Column</option>
                                    <option>8 Column</option>
                                    <option>10 Column</option>
                                    <option>12 Column</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-check">
                              <div class="col-auto">
                                 <div class="form-check form-check-inline">
                                       <input class="form-check-input" type="radio" name="radioFilter" checked>
                                       <label class="form-check-label" for="inlineRadio1">Component</label>
                                 </div>
                                 <div class="form-check form-check-inline">
                                       <input class="form-check-input" type="radio" name="radioFilter">
                                       <label class="form-check-label" for="inlineRadio2">HTML</label>
                                 </div>
                              </div>
                           </div>
                           <div class="form-group">
                              <label class="col-12 control-label text-left">Component</label>
                              <div class="col-12">
                               <select class="col-12 form-control">
                                   <option>Recent Interaction</option>
                                    <option>Answer This Week</option>
                                    <option>Get Support</option>
                                 </select>
                              </div>
                           </div>
                           <div class="col-md-12 mbottom-15">
                              <!--v-if--><!--v-if--><span><a class="btn btn-xs btn-danger mbottom-15">Remove</a></span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-2 pt-3">
                  <span><a class="btn btn-xs btn-primary mbottom-15">Row Up</a><br></span><!--v-if--><span><a class="btn btn-xs btn-primary mbottom-15">Add Column</a></span><span><a class="btn btn-xs btn-danger mbottom-15">Remove Row</a></span>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-md-12">
         <div class="dashboard-row">
            <div class="row">
               <div class="col-md-10"></div>
               <div class="col-md-2  pt-3"><a class="btn btn-xs btn-primary mbottom-15">Add Row<br></a></div>
            </div>
         </div>
      </div>
   </div>
   <p>Default dashboard components are: <b>dashboard-recent-interactions</b>, <b>dashboard-my-answers-this-week</b>, and <b>dashboard-get-support</b>. <button type="button" class="btn btn-primary margin-r-5">Reset</button></p>
   <button type="button" class="btn btn-primary margin-r-5">Save</button>
   <div>
      <!--v-if-->
   </div>
</div>
        <!-- /.row -->
        <!-- END ACCORDION & CAROUSEL-->
</template>
<script>
var previousrowVal;
export default {
  name: 'Dashboard',
  components: {
   
  },
  data: function(){
      return{
        segment3 : false,
        segment4 : false,
        segment5 : false,
        segment6 : false,
        showDashboardDetails : false,
        value: true,
        active_el : '',
        colEmail : false,
        createNewDashboard:false,
        showAlertDashboard:false
      }
         
  },
   methods: {
    toggleSegmentCollapsedStateDahboard(i) {
      this['segment'+i] = !this['segment'+i] 
    },
    showMessageDashboardDetails(type,row) {
      if(previousrowVal == row){
          this.showDashboardDetails = !this.showDashboardDetails;
          if(!this.showDashboardDetails){
            this.active_el = '';
          }else{
            this.active_el = row;  
          }
         
      }else{
         this.showDashboardDetails = this.showDashboardDetails;
            this.active_el = row;     
      }
      previousrowVal = row;
      
      this.showDashboardDetails = true;
      this.createNewDashboard = false;
      
      //this.emitter.emit("show-message-details", {displayType:type, showDashboardDetails : this.showDashboardDetails});
      
    }
  }
}

</script>